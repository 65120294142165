import './App.css';

function App() {
  window.location.href="https://www.vivliohealth.com"
  return (
    <div className="App">
      Redirecting...
    </div>
  );
}

export default App;
